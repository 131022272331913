import { FederatedModule } from '@sennder/federated-module-loader'
import {
  INotificationCenterData,
  ISennTmsSharedData,
  IWidgetSharedData,
} from '@sennder/senn-node-microfrontend-interfaces'

import { ENV } from '@/common/config'
import { logger } from '@/services/logger/loggers'

/**
 * Unique micro-frontend identifier
 */
export type MicrofrontendId = keyof typeof microfrontends

/**
 * All unique micro-frontends which can be loaded in the application
 */
export const microfrontends = {
  auth: new FederatedModule<ISennTmsSharedData>({
    devPort: 9110,
    environment: ENV,
    moduleFederationPluginName: 'internalauthmfcomponent',
    npmName: 'internal-auth-mf-component-v2',
    mountExposedName: './internalauthmfcomponentApp',
    logger,
    cacheType: 'infinite',
  }),
  analysis: new FederatedModule<ISennTmsSharedData>({
    devPort: 9182,
    environment: ENV,
    moduleFederationPluginName: 'analysismfcomponent',
    npmName: 'analysis-mf-component',
    mountExposedName: './analysismfcomponentApp',
    logger,
    cacheType: 'infinite',
  }),
  octopus: new FederatedModule<ISennTmsSharedData>({
    devPort: 9111,
    environment: ENV,
    moduleFederationPluginName: 'octopuscoremfcomponent',
    npmName: 'octopus-core-mf-component',
    mountExposedName: './octopuscoremfcomponentApp',
    logger,
    cacheType: 'infinite',
  }),
  octopus_modal: new FederatedModule<ISennTmsSharedData>({
    devPort: 9111,
    environment: ENV,
    moduleFederationPluginName: 'octopuscoremfcomponent',
    npmName: 'octopus-core-mf-component',
    mountExposedName: './octopuscoremfcomponentModal',
    logger,
    cacheType: 'infinite',
  }),
  new_shipment: new FederatedModule<ISennTmsSharedData>({
    devPort: 9113,
    environment: ENV,
    moduleFederationPluginName: 'shipmentmfcomponent',
    npmName: 'shipment-mf-component',
    mountExposedName: './shipmentmfcomponentApp',
    logger,
    cacheType: 'infinite',
  }),
  new_shipment_modal: new FederatedModule<ISennTmsSharedData>({
    devPort: 9113,
    environment: ENV,
    moduleFederationPluginName: 'shipmentmfcomponent',
    npmName: 'shipment-mf-component',
    mountExposedName: './shipmentmfcomponentCreateShipmentModal',
    logger,
    cacheType: 'infinite',
  }),
  'single-view': new FederatedModule<ISennTmsSharedData>({
    devPort: 9112,
    environment: ENV,
    moduleFederationPluginName: 'singleviewmfcomponent',
    npmName: 'single-view-mf-component',
    mountExposedName: './singleviewmfcomponentApp',
    logger,
    cacheType: 'infinite',
  }),
  dispatching: new FederatedModule<ISennTmsSharedData>({
    devPort: 9105,
    environment: ENV,
    moduleFederationPluginName: 'dispatchingboardmfcomponent',
    npmName: 'dispatching-board-mf-component',
    mountExposedName: './dispatchingboardmfcomponentApp',
    logger,
    cacheType: 'infinite',
  }),
  spotter: new FederatedModule<ISennTmsSharedData>({
    devPort: 9150,
    environment: ENV,
    moduleFederationPluginName: 'spotter',
    npmName: 'spotter',
    mountExposedName: './spotterApp',
    logger,
    cacheType: 'infinite',
  }),
  'carrier-search': new FederatedModule<ISennTmsSharedData>({
    devPort: 9101,
    environment: ENV,
    moduleFederationPluginName: 'carriersearchmfcomponent',
    npmName: 'carrier-search-mf-component',
    mountExposedName: './carriersearchmfcomponentApp',
    logger,
    cacheType: 'infinite',
  }),
  'carrier-profile': new FederatedModule<ISennTmsSharedData>({
    devPort: 9102,
    environment: ENV,
    moduleFederationPluginName: 'carrierprofilemfcomponent',
    npmName: 'carrier-profile-mf-component',
    mountExposedName: './carrierprofilemfcomponentApp',
    logger,
    cacheType: 'infinite',
  }),
  'facility-management': new FederatedModule<ISennTmsSharedData>({
    devPort: 9103,
    environment: ENV,
    moduleFederationPluginName: 'facilitymanagementmfcomponent',
    npmName: 'facility-management-mf-component',
    mountExposedName: './facilitymanagementmfcomponentApp',
    logger,
    cacheType: 'infinite',
  }),
  'vetting-review': new FederatedModule<ISennTmsSharedData>({
    devPort: 9102,
    environment: ENV,
    moduleFederationPluginName: 'vettingreviewmfcomponent',
    npmName: 'vetting-review-mf-component',
    mountExposedName: './vettingreviewmfcomponentApp',
    logger,
    cacheType: 'infinite',
  }),
  'vetting-dashboard': new FederatedModule<ISennTmsSharedData>({
    devPort: 9114,
    environment: ENV,
    moduleFederationPluginName: 'vetting_micro_frontends',
    npmName: 'vetting_micro_frontends',
    mountExposedName: './vettingDashboardApp',
    logger,
    cacheType: 'infinite',
  }),
  blocklisting: new FederatedModule<ISennTmsSharedData>({
    devPort: 9114,
    environment: ENV,
    moduleFederationPluginName: 'vetting_micro_frontends',
    npmName: 'vetting_micro_frontends',
    mountExposedName: './blocklistingDashboardApp',
    logger,
    cacheType: 'infinite',
  }),
  sennteams: new FederatedModule<ISennTmsSharedData>({
    devPort: 9154,
    environment: ENV,
    moduleFederationPluginName: 'sennteamsmfcomponent',
    npmName: 'sennteams-mf-component',
    mountExposedName: './sennteamsmfcomponentApp',
    logger,
    cacheType: 'infinite',
  }),
  sennlab: new FederatedModule<ISennTmsSharedData>({
    devPort: 9106,
    environment: ENV,
    moduleFederationPluginName: 'sennlab',
    npmName: 'sennlab',
    mountExposedName: './sennlabApp',
    logger,
    cacheType: 'infinite',
  }),
  planner: new FederatedModule<ISennTmsSharedData>({
    devPort: 9198,
    environment: ENV,
    moduleFederationPluginName: 'plannermfcomponent',
    npmName: 'planner-mf-component',
    mountExposedName: './plannermfcomponentApp',
    logger,
    cacheType: 'infinite',
  }),
  contracts: new FederatedModule<ISennTmsSharedData>({
    devPort: 8084,
    environment: ENV,
    moduleFederationPluginName: 'chartering',
    npmName: 'chartering',
    mountExposedName: './charteringApp',
    logger,
    cacheType: 'infinite',
  }),
  marketplace: new FederatedModule<ISennTmsSharedData>({
    devPort: 9104,
    environment: ENV,
    moduleFederationPluginName: 'marketplace',
    npmName: 'marketplace',
    mountExposedName: './marketplaceApp',
    logger,
    cacheType: 'infinite',
  }),
  'search-bar': new FederatedModule<IWidgetSharedData>({
    devPort: 9111,
    environment: ENV,
    moduleFederationPluginName: 'searchbarmfcomponent',
    npmName: 'search-bar-mf-component',
    mountExposedName: './searchbarmfcomponentApp',
    cacheType: 'infinite',
  }),
  notifications: new FederatedModule<INotificationCenterData>({
    devPort: 9199,
    environment: ENV,
    moduleFederationPluginName: 'notificationcentermf',
    npmName: 'notification-center-mf',
    mountExposedName: './notificationcentermfApp',
    cacheType: 'infinite',
  }),
} as const satisfies {
  [TMicroFrontend: string]:
    | FederatedModule<ISennTmsSharedData>
    | FederatedModule<IWidgetSharedData>
    | FederatedModule<INotificationCenterData>
}
