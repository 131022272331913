import { OrgType } from '@sennder/senn-node-microfrontend-interfaces'
import { UserAccessHandler } from '@sennder/shell-utilities'

import { getStateData, getStateFeatureFlags } from '@/store'

const USER_GROUPS = {
  ACCOUNT_MANAGERS: 'AccountManager',
  PARTNER_MANAGERS: 'PartnerManager',
  FINANCE: 'Finance',
  CLAIM_ACCESSORIAL_MANAGER: 'ClaimAccessorialManager',
  FINANCE_AR: 'FinanceAccountsReceivable',
}

/**
 * Access handlers are functions which return a boolean. They can be used as menu visibility handlers, and called in route guards.
 *
 * NAMING CONVENTIONS
 *
 * Access handler name should be a sentence which describes what access it checks, or user role it checks.
 *
 * - userIs{Role}: Check if the user has a specific role
 * - canAccess{Page}: Check if the user has access to the page
 */

export const userIsAccountManager: UserAccessHandler = () => {
  return !!getStateData().user?.groupNames.includes(
    USER_GROUPS.ACCOUNT_MANAGERS
  )
}

export const userIsPartnerManager: UserAccessHandler = () => {
  return !!getStateData().user?.groupNames.includes(
    USER_GROUPS.PARTNER_MANAGERS
  )
}

export const userIsFinance: UserAccessHandler = () => {
  return !!getStateData().user?.groupNames.includes(USER_GROUPS.FINANCE)
}

export const userIsFinanceAR: UserAccessHandler = () => {
  return !!getStateData().user?.groupNames.includes(USER_GROUPS.FINANCE_AR)
}

export const canAccessSingleView: UserAccessHandler = () => {
  const isEnabledForCm = getStateFeatureFlags()['ENABLE_ONE-VIEW-BOARD']
  return !!(
    userIsAccountManager() ||
    (isEnabledForCm && userIsPartnerManager())
  )
}

export const canAccessClaimsBoard: UserAccessHandler = () => {
  const currentUserIsClaimAccessorialManager =
    !!getStateData().user?.groupNames.includes(
      USER_GROUPS.CLAIM_ACCESSORIAL_MANAGER
    )
  return currentUserIsClaimAccessorialManager || userIsFinance()
}

export const canAccessFinanceDashboard: UserAccessHandler = () =>
  !!userIsFinance()

export const canAccessMyCarriers: UserAccessHandler = () =>
  !!getStateFeatureFlags()['SHOW_MY-CARRIERS-PAGE']

export const canAccessAnalysisBoard: UserAccessHandler = () =>
  !!getStateFeatureFlags()['ENABLE_ANALYSIS-BOARD']

export const canAccessCharteringPages = ((org = getStateData().org) => {
  return !!(
    org?.orgType === OrgType.CHARTERING_OFFICE &&
    getStateFeatureFlags()['enable-chartering-mfs-senn-tms']
  )
}) satisfies UserAccessHandler
