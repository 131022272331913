import { createApp } from 'vue'
import { initializeVue as initLaunchDarkly } from '@sennder/senn-node-feature-flags-frontend'
import '@/components/orchestrated-layout'
import App from './App.vue'
import router from './router'
import { logger } from '@/services/logger/loggers'
import { LAUNCH_DARKLY_KEY } from './common/config'
import { registerPlugins } from './plugins'
import { loadUserData } from '@/store/loadUserData'
import { initRouteGuards, registerRoutes } from './router/routes'
import { auth, loadAuthRemoteFunction } from '@/modules/auth'
import { startTracking } from './services/tracking'
import { getLaunchDarklyAnonymousContext } from './services/launchDarkly'
import { registerCustomElements } from '@/components/orchestrated-layout'
import { translationProvider } from '@/services/translationProvider'
import { loadStyles, themeProvider, Themes } from '@sennder/design-system-core'

async function initialize() {
  window.onerror = function (message, url, line, column, error) {
    logger.error('[tms - global error]', { error })
  }

  if (!LAUNCH_DARKLY_KEY) {
    throw new Error('LAUNCH_DARKLY_KEY not found')
  }

  await initLaunchDarkly(LAUNCH_DARKLY_KEY, getLaunchDarklyAnonymousContext())

  await Promise.all([
    loadAuthRemoteFunction(),
    registerRoutes(),
    translationProvider.initialize(),
  ])

  if (await auth.value?.isAuthenticated()) {
    await loadUserData()
  }
}

async function start() {
  // TODO: if required, check for user consent before starting tracking
  startTracking()

  try {
    await Promise.all([loadStyles('#app'), initialize()])
  } catch (error) {
    logger.error(`[tms] shell init`, { error })
  } finally {
    initRouteGuards(router)
    themeProvider('body', Themes.Light)
    const app = createApp(App).use(router)
    await router.isReady()
    registerPlugins(app)
    registerCustomElements()
    app.mount('#app')
  }
}

start()
