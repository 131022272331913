import { AppNavigationGuard } from '@sennder/shell-utilities'

import { INITIAL_ROUTE_PATH } from '@/router'
import { getStateData, getStateFeatureFlags } from '@/store'
import {
  canAccessCharteringPages,
  canAccessClaimsBoard,
  canAccessFinanceDashboard,
  canAccessMyCarriers,
  canAccessSingleView,
  userIsAccountManager,
  userIsFinance,
  userIsFinanceAR,
  userIsPartnerManager,
} from './user-access'
import { isProductionEnv } from '@/common/config'

/**
 * Router Navigation Guards
 *
 * NAMING CONVENTIONS
 *
 * Route guard name should be a verb that describes what the guard does.
 *
 * - check{Page}Access: Check if the user has access to the page
 * - redirect{Page}: Redirect the user to a different page based on some criteria
 * - restrict{Criteria}: Restrict access to the page based on some criteria
 */

/**
 * Automatically redirect AOMs to Single View if REDIRECT_DISPATCHING-BOARD is enabled
 */
export const checkDispatchingBoardAccess: AppNavigationGuard = async () => {
  const data = getStateData()

  const shouldRedirectToSingleView =
    data.featureFlags['REDIRECT_DISPATCHING-BOARD']

  if (!shouldRedirectToSingleView || !userIsAccountManager()) {
    return true
  } else {
    return '/single-view/unmatched'
  }
}

/**
 * Do not allow Finance-only users to enter this page.
 */
export const checkOrderingPageAccess: AppNavigationGuard = async (to) => {
  if (userIsFinance() && !(userIsAccountManager() || userIsPartnerManager())) {
    return `/finance/ordering/${to.params.orderGroupId}${to.params.letter}`
  } else {
    return true
  }
}

/**
 * Redirect users to the correct Finance View based on their roles.
 */
export const redirectFinanceOrdering: AppNavigationGuard = async (to) => {
  if (userIsFinanceAR()) {
    return `/finance/ordering/${to.params.orderGroupId}${to.params.letter}/ar`
  }

  if (!userIsFinance()) {
    return `/ordering/${to.params.orderGroupId}${to.params.letter}`
  }

  return `/finance/ordering/${to.params.orderGroupId}${to.params.letter}/ap`
}

/**
 * Redirect non-finance users from the Finance page.
 */
export const redirectFinanceView: AppNavigationGuard = async (to) => {
  if (!userIsFinance()) {
    return `/ordering/${to.params.orderGroupId}${to.params.letter}`
  }

  return true
}

/**
 * Enable this page only in non-prod envs
 */
export const restrictInProduction: AppNavigationGuard = async () => {
  if (isProductionEnv()) {
    return { path: INITIAL_ROUTE_PATH }
  }
  return true
}

/**
 * This function determines whether the module should be visible to the user
 * based on the organization type.
 * Consider permission screen for the users that are not part of the chartering office
 */
export const restrictForCharteringOfficeUsers: AppNavigationGuard =
  async () => {
    if (canAccessCharteringPages()) {
      return true
    }
    return { path: INITIAL_ROUTE_PATH }
  }

export const checkClaimsBoardAccess: AppNavigationGuard = () => {
  if (canAccessClaimsBoard()) {
    return true
  }
  return { path: INITIAL_ROUTE_PATH }
}

export const checkFinanceDashboardAccess: AppNavigationGuard = () => {
  if (canAccessFinanceDashboard()) {
    return true
  }
  return { path: INITIAL_ROUTE_PATH }
}

export const checkMyCarriersAccess: AppNavigationGuard = () => {
  if (canAccessMyCarriers()) {
    return true
  }
  return { path: INITIAL_ROUTE_PATH }
}

export const checkSingleViewAccess: AppNavigationGuard = () => {
  if (canAccessSingleView()) {
    return true
  }
  return { path: INITIAL_ROUTE_PATH }
}

export const redirectToNewShipmentDraft: AppNavigationGuard = (to) => {
  if (getStateFeatureFlags()['ENABLE_NEW_SHIPMENT_DRAFT_REDIRECT']) {
    return { name: 'new_shipment', params: to.params }
  }
  return true
}
