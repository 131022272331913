<template>
  <Suspense v-if="isLocalEnv()">
    <dev-panel
      style-overrides
      :module-configuration="{
        moduleConfiguration: microfrontendConfigs,
      }"
      :feature-flags="{
        updateFlag: setFeatureFlag,
        flags: featureFlags,
      }"
      :state="{
        state,
        updateState: setData,
      }"
      :overrides="{
        modules,
        onToggleHotMode,
      }"
      log-requests
      :pkg-json="pkgJson"
    >
    </dev-panel>
  </Suspense>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { DevPanel } from '@sennder/shell-utilities'

import { isLocalEnv } from '@/common/config'
import { type SennTmsStoreData } from '@/store'
import {
  getStateData,
  getStateFeatureFlags,
  setStoreFeatureFlags,
  setStoreData,
} from '@/store'

import router from '@/router'
import pkgJson from '../../package.json'
import { microfrontends } from '@/config/microfrontends'

const featureFlags = computed(getStateFeatureFlags)
const state = computed(getStateData)

const microfrontendConfigs = Object.values(microfrontends)

const modules = new Map(
  microfrontendConfigs.map((federatedModule) => {
    return [federatedModule.npmName, federatedModule.devPort]
  })
)

const onToggleHotMode = async (npmName: string) => {
  // If this is the module we're currently rendering, let's reload the page to mount the new version
  if (router.currentRoute.value.meta.npmName === npmName) {
    const { fullPath } = router.currentRoute.value
    await router.push('/reload')
    await router.push(fullPath)
  }
}

const setData = (state: object) => {
  setStoreData(state as SennTmsStoreData)
}

const setFeatureFlag = (key: string, value: boolean) => {
  setStoreFeatureFlags({
    ...getStateFeatureFlags(),
    [key]: value,
  })
}
</script>
